import {combineReducers, configureStore} from '@reduxjs/toolkit';

import userActions from '../reducers/User/actions';
import mettingActions from '../reducers/Metting/actions';
import modalActions from '../reducers/Modal/actions';
import configActions from '../reducers/Config/actions';
import toasterActions from '../reducers/Toaster/actions';

import emptySplitApi from "@Redak/redux/emptySplitApi";

import {initialUserState, userReducer} from "@Redak/redux/reducers/User";
import {initialConfigState, configReducer} from "@Redak/redux/reducers/Config";
import {initialMettingState, mettingReducer } from "@Redak/redux/reducers/Metting";
import {initialModalState, modalReducer} from "@Redak/redux/reducers/Modal";
import {initialToasterState, toasterReducer} from "@Redak/redux/reducers/Toaster";

export const allStoreActions = {
    users: userActions,
    modals: modalActions,
    toaster: toasterActions,
    config: configActions,
    mettings: mettingActions,
    mettingsCopy: mettingActions,
}

const initialState = {
    user: initialUserState,
    modals: initialModalState,
    config: initialConfigState,
    mettings: initialMettingState,
    toaster: initialToasterState,
    mettingsCopy: initialMettingState,
}

const rootReducer = combineReducers({
    user: userReducer,
    modals: modalReducer,
    config: configReducer,
    toaster: toasterReducer,
    mettings: mettingReducer,
    mettingsCopy: mettingReducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer
});

const gDMOptions = { immutableCheck: false, serializableCheck: false };
export const store = configureStore({
    reducer: rootReducer,
    middleware: (gDM) => gDM(gDMOptions).concat(emptySplitApi.middleware),
    preloadedState: initialState,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;