import React, {useState} from "react";
import {MettingType} from "@Redak/types/Metting/MettingType";
import {Field, Formik} from "formik";
import * as yup from 'yup';
import Shape from "@Redak/types/Common/Shape";
import FormField from "@Redak/Common/Forms/FormField";
import {Card, CardContent} from "@Redak/Common/Card";
import moment from "moment";
import {allStoreActions} from "@Redak/redux/store";
import MettingActions from "@Redak/redux/reducers/Metting/actions";
import {useAppDispatch} from "@Redak/redux/hooks";
import SyncLoader from "react-spinners/ClipLoader";
import {putMetting} from "@Redak/Api/Metting/metting.api";
import message from "@Redak/utils/Data";
import {ToastVariants} from "@Redak/Common/Toaster/type";

const mettingSchema = yup.object<Shape<MettingType>>({
    colorDateSendCustomer: yup.string().nullable(),
    dateReceiptProofreader: yup.date().nullable(),
    dateSendProofreader: yup.date().nullable(),
    prestationType: yup.string().nullable(),
    dateSendCustomer: yup.date().nullable(),
    contributors: yup.string().nullable(),
    dateSendWorker: yup.date().nullable(),
    content: yup.string().nullable(),
    state: yup.boolean().nullable(),
    type: yup.string().nullable(),
});

export function MettingEdit(props: { metting: MettingType }) {
    const {metting} = props;

    const { sendToast } = allStoreActions.toaster;
    const { updateMetting } = MettingActions;
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [initialized] = useState(true);

    const submit = async (form: any) => {
        setIsLoading(true);
        try {
            let values = {
                ...form,
                dateReceiptProofreader: form.dateReceiptProofreader === '' ? null : form.dateReceiptProofreader,
                dateSendProofreader: form.dateSendProofreader === '' ? null : form.dateSendProofreader,
                dateSendCustomer: form.dateSendCustomer === '' ? null : form.dateSendCustomer,
                dateSendWorker: form.dateSendWorker === '' ? null : form.dateSendWorker,
            }

            const response = await putMetting(metting.id, values);
            dispatch(updateMetting({...metting, ...response.data}));
            setIsLoading(false);
            dispatch(sendToast(ToastVariants.SUCCESS, message.toaster.success.metting.update))
        } catch (e) {
            setIsLoading(false);
            dispatch(sendToast(ToastVariants.DANGER, message.toaster.error.metting))
        }
    }

    if(!initialized) {
        return <></>
    }

    const setDefaultValueDate = (date: any) => {
        if (!moment(date).isValid()) {
            return "";
        }

        return moment(date).format('Y-MM-DD');
    }

    return (
        <>
            <div className="container-metting-edit">
                <Formik
                    initialValues={{
                        dateReceiptProofreader: setDefaultValueDate(metting.dateReceiptProofreader),
                        dateSendProofreader: setDefaultValueDate(metting.dateSendProofreader),
                        dateSendCustomer: setDefaultValueDate(metting.dateSendCustomer),
                        dateSendWorker: setDefaultValueDate(metting.dateSendWorker),
                        colorDateSendCustomer: metting.colorDateSendCustomer,
                        prestationType: metting.prestationType,
                        contributors: metting.contributors,
                        content: metting.content,
                        state: metting.state,
                        type: metting.type,
                    }}
                    validationSchema={mettingSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={submit}
                >
                    {
                        ({handleSubmit, errors, values, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                <Card>
                                    <CardContent title={'Informations générales :'}>
                                        <>

                                            <div className="form-check mb-2 mt-2">
                                                <input
                                                    onChange={(e) => setFieldValue('state', e.target.checked)}
                                                    className="form-check-input"
                                                    checked={values.state}
                                                    type="checkbox"
                                                    id="state"
                                                />
                                                    <label className="form-check-label" htmlFor="state">
                                                        Veuillez cocher si la réunion terminée
                                                    </label>
                                            </div>

                                            <hr />

                                            <FormField
                                                error={errors.type}
                                                label={"Client :"}
                                            >
                                                <Field
                                                    value={metting.customer.fullName}
                                                    className="form-control"
                                                    name={'contributors'}
                                                    disabled={true}
                                                    type="text"
                                                />
                                            </FormField>

                                            <FormField
                                                label={"Participants :"}
                                                name={'contributors'}
                                                error={errors.type}
                                            >
                                                <Field
                                                    value={values.contributors}
                                                    className="form-control"
                                                    name={'contributors'}
                                                    type="text"
                                                />
                                            </FormField>

                                            <div className="form-row">
                                                <FormField
                                                    className={'form-group col-md-6 mb-0 pb-0'}
                                                    label={"Type de réunion :"}
                                                    error={errors.type}
                                                    name={'type'}
                                                >
                                                    <Field
                                                        name={'type'} type="text"
                                                        className="form-control"
                                                        value={values.type}
                                                    />
                                                </FormField>

                                                <FormField
                                                    className={'form-group col-md-6 mb-0 pb-0'}
                                                    label={"Type de prestation :"}
                                                    name={'prestationType'}
                                                    error={errors.prestationType}
                                                >
                                                    <Field
                                                        value={values.prestationType}
                                                        className="form-control"
                                                        name={'prestationType'}
                                                        type="text"
                                                    />
                                                </FormField>
                                            </div>

                                            <div className="form-row">
                                                <FormField
                                                    className={'form-group col-md-6 mb-0 pb-0'}
                                                    label={"Date de la réunion :"}
                                                >
                                                    <Field
                                                        value={moment(metting.date).format('Y-MM-DD')}
                                                        name={'startRedak'} type="date"
                                                        className="form-control"
                                                        disabled={true}
                                                    />
                                                </FormField>

                                                <FormField
                                                    className={'form-group col-md-6 mb-0 pb-0'}
                                                    label={"Durée de la réunion :"}
                                                >
                                                    <Field
                                                        value={metting.duration}
                                                        className="form-control"
                                                        disabled={true}
                                                        type="text"
                                                    />
                                                </FormField>
                                            </div>

                                            <hr />

                                            <FormField
                                                error={errors.type} label={"Sténotypiste / Rédacteur :"}
                                                name={'type'}
                                            >
                                                <Field
                                                    value={metting.worker.fullName}
                                                    className="form-control"
                                                    disabled={true}
                                                    type="text"
                                                />
                                            </FormField>

                                            <FormField
                                                label={"Date d'envoi de son TAO :"}
                                                error={errors.dateSendWorker}
                                                name={'dateSendWorker'}
                                            >
                                                <Field
                                                    value={values.dateSendWorker}
                                                    className="form-control"
                                                    name={'dateSendWorker'}
                                                    type="date"
                                                />
                                            </FormField>

                                            <hr />

                                            <FormField label={"Relecteur :"}>
                                                <Field
                                                    value={metting.proofreader?.fullName}
                                                    className="form-control"
                                                    disabled={true}
                                                    type="text"
                                                />
                                            </FormField>

                                            <div className="form-row">
                                                <FormField
                                                    label={"Date d'envoi de son TAO au relecteur :"}
                                                    className={'form-group col-md-6 mb-0 pb-0'}
                                                    error={errors.dateSendProofreader}
                                                    name={'dateSendProofreader'}
                                                >
                                                    <Field
                                                        value={values.dateSendProofreader}
                                                        className="form-control"
                                                        name={'dateSendProofreader'}
                                                        type="date"
                                                    />
                                                </FormField>
                                                <FormField
                                                    className={'form-group col-md-6 mb-0 pb-0'}
                                                    label={"Date d'envoi par le relecteur :"}
                                                    error={errors.dateReceiptProofreader}
                                                    name={'dateReceiptProofreader'}
                                                >
                                                    <Field
                                                        value={values.dateReceiptProofreader}
                                                        name={'dateReceiptProofreader'}
                                                        className="form-control"
                                                        type="date"
                                                    />
                                                </FormField>
                                            </div>

                                            <hr />
                                            
                                            <div className="form-row">
                                                <FormField
                                                    className={'form-group col-md-4 mb-0 pb-0'}
                                                    label={"Délais du client :"}
                                                >
                                                    <Field
                                                        value={moment(metting.delayCustomer).format('Y-MM-DD')}
                                                        className="form-control"
                                                        disabled={true}
                                                        type="date"
                                                    />
                                                </FormField>

                                                <FormField
                                                    className={'form-group col-md-4 mb-0 pb-0'}
                                                    label={"Date d'envoi au client :"}
                                                    error={errors.dateSendCustomer}
                                                    name={'dateSendCustomer'}
                                                >
                                                    <Field
                                                        value={values.dateSendCustomer}
                                                        className="form-control"
                                                        name={'dateSendCustomer'}
                                                        type="date"
                                                    />
                                                </FormField>

                                                <FormField
                                                    className={'form-group col-md-4 mb-0 pb-0'}
                                                    label={"Code couleur :"}
                                                    error={errors.colorDateSendCustomer}
                                                    name={'colorDateSendCustomer'}
                                                >
                                                    <Field
                                                        value={values.colorDateSendCustomer}
                                                        name={'colorDateSendCustomer'}
                                                        className="form-control"
                                                        type="color"
                                                    />
                                                </FormField>
                                            </div>

                                            <FormField error={errors.content} label={"La demande d’extrait / date d’envoi :"} name={'content'}>
                                                <textarea
                                                    onChange={(e) => setFieldValue('content', e.target.value)}
                                                    value={values.content ?? undefined}
                                                    style={{resize: 'vertical'}}
                                                    className="form-control"
                                                    maxLength={5000}
                                                    name="content"
                                                    rows={2}
                                                ></textarea>
                                            </FormField>
                                        </>
                                    </CardContent>
                                </Card>

                                <div className="form-group d-flex justify-content-center w-100">
                                    <button
                                        className={'btn btn-block btn btn-primary btn-rounded mt-3'}
                                        style={{maxWidth: 200, height: 40}}
                                        disabled={isLoading}
                                        type={'submit'}
                                    >
                                        {
                                            isLoading ? (
                                                <SyncLoader color={'#fff'} loading={true} size={23}/>
                                            ) : (
                                                'Enregistrer'
                                            )
                                        }
                                    </button>
                                </div>
                            </form>
                        )
                    }
                </Formik>
            </div>
        </>
    );
}

export default MettingEdit;