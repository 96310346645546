import React, {useEffect, useState} from "react";
import {CardMettingInformation} from "@Redak/Common/Metting/Card/CardMettingInformation";
import MettingEdit from "@Redak/Common/Metting/View/Management/MettingEdit";
import {findMettingById} from "@Redak/utils/Metting/findMetting";
import ModalActions from "@Redak/redux/reducers/Modal/actions";
import {MettingType} from "@Redak/types/Metting/MettingType";
import {useAppDispatch} from "@Redak/redux/hooks";
import {useSelector} from "react-redux";

export function MettingShow(props: { initialMetting: MettingType }) {
    const { initialMetting } = props;

    const modals = useSelector((state: any) => state.modals.modals);
    const [isLoading, setIsLoading] = useState(true);
    const [metting, setMetting] = useState(Object);

    const dispatch = useAppDispatch();
    const { push } = ModalActions;

    const init = async () => {
        setMetting(await findMettingById(initialMetting));
    }

    useEffect(() => {
        init().then(() => setIsLoading(false));
    }, [modals.length])

    const handleEditMetting = () => {
        dispatch(
            push(
                <MettingEdit metting={metting} />, {
                    title: `Éditer - ${metting.customer.fullName}`,
                    className: '',
                    type: 'update'
                }
            )
        );
    }

    if(isLoading) {
        return <></>;
    }

    return (
        <>
            <CardMettingInformation
                metting={metting}
            />

            <hr />

            <div className="d-flex justify-content-center">
                <button className={'btn btn-primary mx-2'} onClick={handleEditMetting}>
                    Modifier
                </button>
            </div>
        </>
    );
}

export default MettingShow;

