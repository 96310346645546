import {MettingType} from "@Redak/types/Metting/MettingType";
import {IMettingBaseActions, MettingActionType} from "@Redak/redux/reducers/Metting/actions";

export interface MettingStateType {
    mettings: Array<MettingType> | [],
    mettingsCopy: Array<MettingType> | [],
}

export const initialMettingState: MettingStateType = {
    mettings: [],
    mettingsCopy: [],
}

export const mettingReducer = (
    state: any = initialMettingState, action: IMettingBaseActions
) => {
    switch (action.type) {
        case MettingActionType.SET_METTINGS: {
            return {
                ...state,
                mettings: action.payload
            }
        }
        case MettingActionType.SET_METTINGS_COPY: {
            return {
                ...state,
                mettingsCopy: action.payload
            }
        }
        case MettingActionType.UPDATE_METTING: {
            return{
                ...state,
                mettings: state.mettings.map((metting: MettingType) => {
                    if(metting.id === action.payload.id) {
                        return {
                            ...metting,
                            ...action.payload
                        }
                    }
                    return metting
                }),
                mettingsCopy: state.mettingsCopy.map((metting: MettingType) => {
                    if(metting.id === action.payload.id) {
                        return {
                            ...metting,
                            ...action.payload
                        }
                    }
                    return metting
                }),
            }
        }
        default: {
            return state;
        }
    }
}