import {useSelector} from "react-redux";
import {RootState, store} from "@Redak/redux/store";
import {MettingType} from "@Redak/types/Metting/MettingType";
import React from "react";
import moment from "moment";
import FilterTable from "@Redak/Screen/Management/Table/FilterTable";
import ModalActions from "@Redak/redux/reducers/Modal/actions";
import {useAppDispatch} from "@Redak/redux/hooks";
import MettingShow from "@Redak/Common/Metting/View/Management/MettingShow";
import {truncateStr} from "@Redak/utils";

export function Table() {
    useSelector<RootState, {
        mettings: MettingType[],
    }>((state) => state.mettingsCopy);

    const dispatch = useAppDispatch();
    const { push } = ModalActions;

    const handleShow = (metting: MettingType) => {
        dispatch(
            push(
                <MettingShow initialMetting={metting} />, {
                    title: metting.customer.fullName,
                    className: '',
                    type: 'update'
                }
            )
        );
    }

    const displayText = (t: any ): string => {
        if(!t) {
            return '--';
        }
        return t;
    }

    const displayDate = (d: any): string => {
        if(!moment(d).isValid()) {
            return '--'
        }
        return moment(d).format('DD-MM-YYYY');
    }

    return (
        <div className="container-table">
            {/*<div className="container-filter-small">
                <img src={require('../../../asset/images/filter.png')} />
            </div>*/}
            <div className="card" style={{ background: '#F1C40E' }}>
                <FilterTable />
            </div>
            {store.getState().mettings.mettingsCopy.length ? (
                <p className="text-left">Nombre de réunions : {store.getState().mettings.mettingsCopy.length}</p>
            ) : <></>}

            {!store.getState().mettings.mettingsCopy.length ? (
                <p className="text-center">Aucune réunion disponible</p>
            ) : (
                <div className="container-table-content">
                    <table className="table--primary table table-hover">
                        <thead>
                            <tr className="classic">
                                <th className="classic left right" scope="col">État</th>
                                <th className="classic right" scope="col">Client</th>
                                <th className="classic right" scope="col">Participants</th>
                                <th className="classic right" scope="col">Type de réunion</th>
                                <th className="classic right" scope="col">Type de prestation</th>
                                <th className="classic right" scope="col">Date de la réunion</th>
                                <th className="classic right" scope="col">Durée de la réunion</th>
                                <th className="classic right" scope="col">Sténotypiste / Rédacteur</th>
                                <th className="classic right" scope="col">Date d'envoi de son TAO</th>
                                <th className="classic right" scope="col">Relecteur</th>
                                <th className="classic right" scope="col">Date d'envoi de son TAO</th>
                                <th className="classic right" scope="col">Date d'envoi par le relecteur</th>
                                <th className="classic right" scope="col">Délais client</th>
                                <th className="classic right" scope="col">Date d'envoi au client</th>
                                <th className="classic right" scope="col">Demande d'extrait / Date d'envoi</th>
                            </tr>
                        </thead>
                        <tbody>
                        {store.getState().mettings.mettingsCopy.map((metting: MettingType) => (
                            <tr
                                style={{background: `${metting.state ? 'rgb(0 138 255 / 19%)' : 'white'}`}}
                                onClick={() => handleShow(metting)}
                                key={metting.id}
                            >
                                <td className="classic left right time">
                                    {metting.state ? 'Terminée' : 'En cours'}
                                </td>
                                <td className="classic left right user">{displayText(metting.customer.fullName)}</td>
                                <td className="classic right user">{displayText(metting.contributors)}</td>
                                <td className="classic right type">{displayText(metting.type)}</td>
                                <td className="classic right type">{displayText(metting.prestationType)}</td>
                                <td className="classic right date">{moment(metting.date).format('DD-MM-YYYY')}</td>
                                <td className="classic right time">{displayText(metting.duration)}</td>
                                <td className="classic right user">{displayText(metting.worker.fullName)}</td>
                                <td className="classic right date">{displayDate(metting.dateSendWorker)}</td>
                                <td className="classic right user">{displayText(metting.proofreader?.fullName)}</td>
                                <td className="classic right date">{displayDate(metting.dateSendProofreader)}</td>
                                <td className="classic right date">{displayDate(metting.dateReceiptProofreader)}</td>
                                <td className="classic right date">{displayDate(metting.delayCustomer)}</td>
                                <td
                                    style={{
                                        background: metting.colorDateSendCustomer ?
                                            metting.colorDateSendCustomer : (
                                                metting.state ? 'rgb(0 138 255 / 19%)' : 'white'
                                            )
                                    }}
                                    className="classic right date"
                                >
                                    {displayDate(metting.dateSendCustomer)}
                                </td>
                                <td className="classic right content">
                                    {displayText(
                                        truncateStr(
                                            metting.content,
                                            30
                                        )
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default Table;