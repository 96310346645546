import {MettingType} from "@Redak/types/Metting/MettingType";
import moment from "moment";

export function FilterDateMetting(
    mettings: Array<MettingType>,
    propertyStart: string | null,
    propertyEnd: string | null,
    dateStart: string | null,
    dateEnd: string | null,
    type: string,
): Array<MettingType> {
    const results: Array<MettingType> = [];
    mettings.map((metting: MettingType) => {
        if (type === 'FULL') {
            // @ts-ignore
            if(moment(metting[propertyStart]).isAfter(moment(dateStart)) && moment(metting[propertyEnd]).isBefore(moment(dateEnd))) {
                results.push(metting);
            }
        } else if(type === 'AFTER') {
            // @ts-ignore
            if(moment(metting[propertyStart]).isAfter(moment(dateStart))) {
                // @ts-ignore
                results.push(metting);
            }
        } else if(type === 'BEFORE'){
            // @ts-ignore
            if(moment(metting[propertyEnd]).isBefore(moment(dateEnd))) {
                results.push(metting);
            }
        }
    });
    return results;
}