import {MettingType} from "@Redak/types/Metting/MettingType";

export function truncateStr(str: string | null | undefined, numWords: number = 255): string {
    if(str === null || str === undefined) {
        return '--'
    }

    let words = str.split(" ");
    if (words.length <= numWords) {
        return str;
    }

    return words.slice(0, numWords).join(" ") + "...";
}

export function searchContributors(mettings: Array<MettingType>, str: string): Array<MettingType> {
    const words = str.split(/[;,]/);
    const array: Array<MettingType> = [];

    mettings.map((metting) => {
        for (let s = 0; s < words.length; s++) {
            const word = words[s].trim();
            if (metting.contributors?.includes(word)) {
                array.push(metting);
            }
        }
    });

    return array;
}