import React, {useState} from "react";
import {useSelector} from "react-redux";
import {allStoreActions, RootState, store} from "@Redak/redux/store";
import {MettingType} from "@Redak/types/Metting/MettingType";
import {useAppDispatch} from "@Redak/redux/hooks";
import {FilterDateMetting} from "@Redak/utils/Filters/FilterDateMetting";
import {searchContributors} from "@Redak/utils";

interface initialStateType {
    dateStart: string, dateEnd: string, searchCustomer: string, searchWorker: string, contributors: string
}

const initialState: initialStateType = {
    dateStart: '', dateEnd: '', searchCustomer: '', searchWorker: '', contributors: ''
}

export function FilterTable() {
    const {mettings} = useSelector<RootState, {
        mettings: MettingType[],
    }>((state) => state.mettingsCopy);

    const [state, setState] = useState<initialStateType>(initialState);
    const dispatch = useAppDispatch();

    const handleResetFilters = () => {
        setState((prevState: initialStateType) => ({  ...prevState, ...initialState }));
        dispatch(allStoreActions.mettingsCopy.setMettingsCopy(mettings));
    }

    const onChangeStateAction = (state: string, value: any) => {
        setState((prevState: initialStateType) => ({ ...prevState, [state]: value }));
    }

    const onSubmitAction = () => {
        let mettings: Array<MettingType> = store.getState().mettings.mettings;

        if (state.dateStart !== "" && state.dateEnd !== "") {
            mettings = FilterDateMetting(
                mettings, 'date', 'date',
                state.dateStart, state.dateEnd,
                'FULL'
            );
        } else if (state.dateStart !== "" && state.dateEnd === "") {
            mettings = FilterDateMetting(
                mettings, 'date', null,
                state.dateStart, null,
                'AFTER'
            );
        } else if (state.dateEnd !== "" && state.dateStart === "") {
            mettings = FilterDateMetting(
                mettings, null, 'date',
                null, state.dateEnd,
                'BEFORE'
            );
        }

        if (state.contributors !== "") {
            mettings = searchContributors(mettings, state.contributors);
        }

        if (state.searchWorker !== "") {
            mettings = mettings.filter((metting: MettingType) =>
                metting.worker.fullName.normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, "")
                    .toUpperCase().indexOf(
                        state.searchWorker.normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, "")
                            .toUpperCase()
                ) > -1);
        }

        if (state.searchCustomer !== "") {
            mettings = mettings.filter((metting: MettingType) =>
                metting.customer.fullName.normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, "")
                    .toUpperCase().indexOf(
                    state.searchCustomer.normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, "")
                        .toUpperCase()
                ) > -1);
        }

        dispatch(allStoreActions.mettingsCopy.setMettingsCopy(mettings));
    }

    return (
        <form
            className="container-filter-table"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="container-filter-table-fields">
                <input
                    onChange={(e) => onChangeStateAction('contributors', e.target.value)}
                    placeholder="Rechercher par participant(s)"
                    className="form-control m-1 classic"
                    value={state.contributors}
                    style={{width: 300}}
                    type="text"
                />
                <input
                    onChange={(e) => onChangeStateAction('searchWorker', e.target.value)}
                    placeholder="Rechercher un sténotypiste / rédacteur"
                    className="form-control m-1 classic"
                    value={state.searchWorker}
                    style={{width: 300}}
                    type="text"
                />
                <input
                    onChange={(e) => onChangeStateAction('searchCustomer', e.target.value)}
                    placeholder="Rechercher un client"
                    className="form-control m-1 classic"
                    value={state.searchCustomer}
                    style={{width: 250}}
                    type="text"
                />
                <input
                    onChange={(e) => onChangeStateAction('dateStart', e.target.value)}
                    placeholder="Date de début client"
                    className="form-control m-1"
                    style={{width: 150}}
                    value={state.dateStart}
                    type="date"
                />
                <input
                    onChange={(e) => onChangeStateAction('dateEnd', e.target.value)}
                    placeholder="Date de fin client"
                    className="form-control m-1"
                    style={{width: 150}}
                    value={state.dateEnd}
                    type="date"
                />
            </div>
            <div>
                <button
                    className="btn btn-success text-center m-1"
                    onClick={onSubmitAction}
                    type="submit"
                >
                    Actualiser
                </button>
                <button
                    className="btn btn-primary text-center m-1"
                    onClick={handleResetFilters}
                    type="button"
                >
                    Supprimer les filtres
                </button>
            </div>
        </form>
    )
}

export default FilterTable;