export const message = {
    toaster: {
        error: {
            metting: 'Une erreur a été rencontrée. Veuillez vérifier les champs renseignés.',
            accessDenied: 'Vous n\'êtes pas autorisé à accéder à l\'application.',
        },
        success: {
            metting: {
                update: 'La réunion a été enregistrée avec succès !'
            },
            resetPassword: 'Un nouveau mot de passe a été envoyé par e-mail.'
        }
    }
}

export default message;