import React from 'react';
import '../../Styles/breadcrumb.css';
import {NavLink} from "react-router-dom";
import ROUTE from "@Redak/Services/navigation.all";

export const Breadcrumb = () => {
    return (
        <div style={{ position: 'relative' }}>
            <div className="row page-titles" style={{ height: 50, paddingTop: 0 }}>
                <div className="container-onglet-breadcrumb">
                    <NavLink
                        className={(navdata) => (
                            navdata.isActive ?
                                'onglet active'
                                : 'onglet'
                        )}
                        to={ROUTE.MANAGEMENT.TABLE}
                    >
                        <i className="fas fa-briefcase"></i>
                        Liste des réunions
                    </NavLink>
                </div>
            </div>
        </div>
    );
}