import axios from "axios";
import API from "@Redak/Api/api.url";
import TokenStorage from "@Redak/Services/TokenStorage";

const getMettings = () => axios.get(
    API.BASE + API.METTING.ALL,
    TokenStorage.getAuthentification()
)

const getMettingById = (id: number) => axios.get(
    API.BASE + API.METTING.BY + id,
    TokenStorage.getAuthentification()
)

const putMetting = (id: number, form: any) => axios.put(
    API.BASE + API.METTING.PUT + id, form,
    TokenStorage.getAuthentification()
)

export {
    getMettingById,
    getMettings,
    putMetting,
}