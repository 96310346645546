const BASE_SECURITY = '/security';
const BASE_MANAGEMENT = '/management';

const ROUTE = {
    SECURITY: {
        AUTHENTIFICATION: BASE_SECURITY + '/signing',
        LOST_PASSWORD: BASE_SECURITY + '/reset-password',
    },
    MANAGEMENT: {
        TABLE: BASE_MANAGEMENT + '/table'
    }
}

export default ROUTE;