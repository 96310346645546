const BASE_URL = process.env.REACT_APP_BASE_URL
const API_BASE_URL = `${BASE_URL}${process.env.REACT_APP_BASE_API}`;

const API = {
    BASE: API_BASE_URL,
    AUTHENTIFICATION: 'login',
    LOST_PASSWORD: 'reset-password',
    USER: {
        CURRENT: 'me'
    },
    METTING: {
        ALL: 'pilote-rh/plannings?isDelete=false',
        BY: 'pilote-rh/plannings/',
        PUT: 'pilote-rh/plannings/',
    },
    CONFIG: {
        STATE: 'planning-state-redak'
    }
};

export default API;
