import React, {useEffect, useState} from "react";
import {useAppDispatch} from "@Redak/redux/hooks";
import MettingActions from "@Redak/redux/reducers/Metting/actions";
import {getMettingById} from "@Redak/Api/Metting/metting.api";
import {store} from "@Redak/redux/store";
import {MettingType} from "@Redak/types/Metting/MettingType";

export function SocketSubscriber() {
    const [isInitialized, setInitialized] = useState(false);
    const dispatch = useAppDispatch();
    const { updateMetting } = MettingActions;

    useEffect(() => {
        if(!isInitialized) _initialisation();
    }, [isInitialized]);

    const _initialisation = () => {
        setInitialized(true);
        let socket: WebSocket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}`);

        socket.onmessage = function (response: MessageEvent) {
            setTimeout( () => {
                const data = JSON.parse(response.data).message
                store.getState().mettings.mettings.map(async (metting: MettingType) => {
                    if(metting.planning.id === data.id) {
                        if(data.id) {
                            try {
                                const response = await getMettingById(metting.id);
                                dispatch(updateMetting(response.data));
                            }catch (error) {
                                console.log(error)
                            }
                        }
                    }
                });
            }, 5000);
        }

        socket.onerror = function (error: Event) {
            console.log(error)
        }
    }

    return (
        <></>
    );
}

export default SocketSubscriber;