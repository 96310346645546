import React, {ReactElement, useEffect, useState} from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import StoreInitializer from "@Redak/redux/Initializer";
import {Provider, useSelector} from "react-redux";
import {store} from "@Redak/redux/store";
import {RootState} from "@Redak/redux/store";
import ROUTE from "@Redak/Services/navigation.all";
import {UserType} from "@Redak/types/User/UserType";
import TokenStorage from "@Redak/Services/TokenStorage";
import {Header} from "@Redak/Common/Header";
import Security from "@Redak/Screen/Security";
import Management from "@Redak/Screen/Management";
import ContainerModal from "@Redak/Common/Modal/ContainerModal";
import '@Redak/asset/styles/app.scss';
import Toaster from "@Redak/Common/Toaster/View/Toaster";
import SocketSubscriber from "@Redak/utils/Subscriber/SocketSubscriber";

function App(){
    const modals = useSelector<RootState, ReactElement[]>(
        (state) => state.modals.modals
    );

    const { user } = useSelector<RootState, {
        user: UserType
    }>((state) => state.user)

    const [isInitialized, setInitialized] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(
        () => {
            function init() {
                setInitialized(true);
                if(!TokenStorage.isAuthenticated()) {
                    const path = window.location.pathname.slice(0, window.location.pathname.length);
                    if(path === ROUTE.SECURITY.AUTHENTIFICATION || path === ROUTE.SECURITY.LOST_PASSWORD) {
                        return navigate(window.location.pathname);
                    }

                    return navigate(ROUTE.SECURITY.AUTHENTIFICATION)
                }

                if(user !== null) {
                    switch (location.pathname) {
                        case '/': {
                            navigate(ROUTE.MANAGEMENT.TABLE);
                            break;
                        }
                        default: {
                            navigate(ROUTE.MANAGEMENT.TABLE);
                            break;
                        }
                    }
                }
            }
            if(!isInitialized) init();
        },
        [location, isInitialized, navigate, user]
    );

    return (
        <>

            {
                TokenStorage.isAuthenticated() ? (
                    <div id="main-wrapper">
                        <Header />
                        <Routes>
                            <Route path={'/management/*'} element={<Management />}  />
                        </Routes>
                    </div>
                ) : (
                    <Routes>
                        <Route path={'/security/*'} element={<Security />}  />
                    </Routes>
                )
            }
            <ContainerModal>
                {modals}
            </ContainerModal>
            <Toaster />
            <SocketSubscriber />
        </>
    );
}


const AppWrapper = () => {
    return (
        <Provider store={store}>
            <StoreInitializer>
                <App />
            </StoreInitializer>
        </Provider>
    );
};

export default AppWrapper;