import {store} from "@Redak/redux/store";
import {MettingType} from "@Redak/types/Metting/MettingType";

export function findMettingById(metting: MettingType): Promise<MettingType> {
    return new Promise(resolve => {
        store.getState().mettings.mettings.map((m: MettingType) => {
            if(m.id === metting.id) {
                resolve(m);
            }
        });
    })
}