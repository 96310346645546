import React from "react";
import {MettingType} from "@Redak/types/Metting/MettingType";
import moment from "moment";
import 'moment/locale/fr';

export const CardMettingInformation = (props: {
    metting: MettingType
}) => {
    const {metting} = props;

    return (
        <div className="container-metting-show">
            <div className="container-metting-information">
                <h5>Informations générales :</h5>
                <div className="sub-container-metting-information">
                    <i className="fas fa-check"></i>
                    <span>État de la réunion :</span> {metting.state ? 'Terminée' : 'En cours'}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-address-card"></i>
                    <span>Client :</span> {metting.customer.fullName}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-users"></i>
                    <span>Participants :</span> {metting.contributors ? metting.contributors : 'Aucun participant'}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-address-card"></i>
                    <span>Type de réunion :</span> {metting.type ? metting.type : '-'}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-address-card"></i>
                    <span>Type de prestation :</span> {metting.prestationType ? metting.prestationType : '-'}
                </div>
                <div className="sub-container-metting-information">
                    <i className=" fas fa-calendar"></i>
                    <span>Date réunion :</span> {moment(metting.date).format('LL')}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-clock"></i>
                    <span>Durée de la réunion :</span> {metting.duration ? metting.duration : '-'}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-user"></i>
                    <span>{metting.planningType === 'EDITOR' ? 'Rédacteur :' : 'Sténotypiste :'}</span> {metting.worker.fullName}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-calendar"></i>
                    <span>Date d'envoi de son TAO :</span>
                   {metting.dateSendWorker ? (
                       ' ' + moment(metting.dateSendWorker).format('LL')
                   ) : ' Aucune date disponible'}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-user"></i>
                    <span>Relecteur :</span>
                    {metting.proofreader ? (
                        ' ' + metting.proofreader.fullName
                    ) : ' Aucun relecteur'}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-calendar"></i>
                    <span>Date d'envoi de la TAO au relecteur :</span>
                    {metting.dateSendProofreader ? (
                        ' ' + moment(metting.dateSendProofreader).format('LL')
                    ) : ' Aucune date disponible'}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-calendar-check"></i>
                    <span>Date d'envoi par le relecteur :</span>
                    {metting.dateReceiptProofreader ? (
                        ' ' + moment(metting.dateReceiptProofreader).format('LL')
                    ) : ' Aucune date disponible'}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-calendar-times"></i>
                    <span>Délai client :</span>
                    {metting.delayCustomer ? (
                        ' ' + moment(metting.delayCustomer).format('LL')
                    ) : ' Aucune date disponible'}
                </div>
                <div className="sub-container-metting-information">
                    <i className="fas fa-calendar-check"></i>
                    <span>Date d'envoi client :</span>
                    {metting.dateSendCustomer ? (
                        ' ' + moment(metting.dateSendCustomer).format('LL')
                    ) : ' Aucune date disponible'}
                </div>
                <div className="sub-container-metting-information">
                    <span>Commentaire :</span> <br />
                   <span style={{whiteSpace: 'pre-line', textDecoration: 'none', fontWeight: 'normal'}}>
                        {metting.content ? metting.content : 'Aucun commentaire.'}
                    </span>
                </div>
            </div>
        </div>
    )
}