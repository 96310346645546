import {MettingType} from "@Redak/types/Metting/MettingType";

export enum MettingActionType {
    SET_METTINGS = 'SET_METTINGS',
    SET_METTINGS_COPY = 'SET_METTINGS_COPY',
    UPDATE_METTING = 'UPDATE_METTING',
}

export interface IMettingBaseActions {
    type: MettingActionType,
    metting?: MettingType,
    payload?: any,
}

const setMettings = (mettings: Array<MettingType>) => (
    { type: MettingActionType.SET_METTINGS, payload: mettings }
)

const setMettingsCopy = (mettings: Array<MettingType>) => (
    { type: MettingActionType.SET_METTINGS_COPY, payload: mettings }
)

const updateMetting = (metting: MettingType) => (
    { type: MettingActionType.UPDATE_METTING, payload: metting }
)

export default {
    setMettings,
    updateMetting,
    setMettingsCopy,
}